
      .template{
          width: 100%;
          height: 100%;
          overflow-wrap: break-word;
          &.card-template{
              background-color: #FFFFFF;
              border-radius: 20px;
              padding: 16px;
          }
          &.landing-page .content{
              min-height: 100vh;
              display: flex;
              flex-direction: column;
          }
          .header{
            font-size: 20px;
          }
          .subheader{
            font-size: 12px;
          }
          .img-background{
              height: 100%;
              max-width: 100%;
              object-fit: cover;
          }
          .text-block p, .text-block .btn-text{
            font-size: 10px;
          }
          footer{
              font-size: 10px;
              max-width: 100%;
              .divider{
                border: 1px solid #ffff;
              }
              .footer_html{
                word-break: break-all;

                :deep()li{
                  text-align: start!important;
                }
                :deep()p{
                    font-size: inherit !important;
                }
              }
          }
          .start-campaign-button{
              min-width: fit-content;
              overflow: hidden;
          }
          .default-title{
            min-height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
            &.block{
              margin-top: 40px;
            }
          }
          .btn-preview{
            font-size: 13px;
            font-weight: 500;
            color: #0373a8;
            display: flex;
            align-items: center;
            cursor: pointer;
            &.disabled{
              color: #8c8c93;
              cursor: default;
              pointer-events: none;
              .icon{
                filter: contrast(0);
              }
            }
          }
      }
      :deep().ql-size-small{
          font-size: 0.75em!important;
      }
      :deep().ql-size-large{
          font-size: 1.5em!important;
      }
      :deep().ql-size-huge{
          font-size: 2.5em!important;
      }
  